<template>
    <v-container fluid class="pa-6 opeTypeRetroplanning">
        <v-row>
            <v-col cols="12">
                <v-btn small depressed color="primary" class="" @click='openForm()'><v-icon small class="mr-2">$plus_l</v-icon>  {{$t('addMedia')}}</v-btn>
            </v-col>
            <v-col cols="12">
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr>
                        <th class="text-left">
                            {{$t('name')}}
                        </th>
                        <th class="text-left">
                            {{$t('type')}}
                        </th>
                        <th class="text-left">
                            {{$t('visibility')}}
                        </th>
                        <th class="text-left">
                            {{$t('brand')}}
                        </th>
                        <th class="text-right">
                            
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="medias in operationTypeMedias"
                        :key="medias.id"
                        @click="test(medias)"
                        >
                        <td>{{ medias.config.label }}</td>
                        <td>{{ medias.config.files.mime }}</td>
                        <td>{{ medias.config.visibility.text }}</td>
                        <td>{{ medias.config.brands }}</td>
                        <td class="text-right">
                            <v-btn text class="square mr-2" small @click.stop=''><v-icon small>$eye_l</v-icon></v-btn>
                            <v-btn text class="square mr-2" small @click.stop='editItem(medias.id)'><v-icon small>$pencil_l</v-icon></v-btn>
                            <v-btn text class="square" small @click.stop='openConfirm(medias.id)'><v-icon small>$trash_l</v-icon></v-btn>
                        </td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-dialog width="600" v-model="dialogConfirm">
            <v-card>
                <v-toolbar flat dark color="primary" class="toolbar" :style="'background-image:url('+publicPath+'img/blue-background.jpg); background-size:cover; height:63px;'">
                    <v-toolbar-title>{{$t('confirmDeleteLabel')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn depressed outlined color="white" class="mr-3" @click="closeDialog">
                        {{$t('cancelLabel')}}
                    </v-btn>
                    <v-btn depressed outlined color="white" @click="deleteItem">
                        {{$t('confirmLabel')}}
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pt-3">
                    {{$t('confirmDeleteMediasOperationText')}}
                </v-card-text>
            </v-card>
        </v-dialog>
         <FormDialogComponent
            v-if="form"
            ref="componentForm"
            :form="form"
            :action="'SET_DATA'"
            />
    </v-container>
   
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import dateManipulation from '@/mixins/dateManipulation'
import getForm from "@/mixins/mixins";
import FormDialogComponent from '@/components/dialogs/FormDialogComponent'
export default {
    name:"OperationsTypeDetailMedias",
    components:{FormDialogComponent},
    mixins:[dateManipulation, getForm],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            form:null,
            operationtype: {},
            operationTypeMedias: [],
            dialogConfirm:false,
            idToDelete:0
        }
    },
    created() {
        this.customRefresh()
    },
    methods: {
        test(medias){
        },
        editItem(itemId){
            this.getForm('/operationtype/'+this.$route.params.operationtype_id+'/getFormMedias?id='+itemId);
        },
        openForm(){
            this.getForm('/operationtype/'+this.$route.params.operationtype_id+'/getFormMedias?type=medias');
        },
        customRefresh(){
            GenericDataService.getData('/operationtype/'+this.$route.params.operationtype_id+'/get').then((response) => {
                this.operationtype = response.data.data
                this.operationTypeMedias = response.data.data.medias
            })
        },
        openConfirm(itemId){
            this.idToDelete = itemId
            this.dialogConfirm = true
        },
        closeDialog(){
            this.idToDelete = 0
            this.dialogConfirm = false
        },
        deleteItem(){
            GenericDataService.getData('/operationtype/'+this.$route.params.operationtype_id+'/deleteMedias?id='+this.idToDelete).then((response) => {
                this.operationTypeMedias = response.data.data
                this.idToDelete = 0
                this.dialogConfirm = false
            })
        },
    },
}
</script>