<template>
    <v-row class="operationStat">
        <v-col cols="2" v-for="(stat, indexName) in stats" @click="goTo(indexName)" :key="indexName">
            <v-menu
                v-if="Object.keys(stat).length > 1"
                offset-y
                center
                open-on-hover
                :close-on-content-click="false"
                content-class="no-shadow testMenu"
                >
                <template v-slot:activator="{ on, attrs }">
                    <v-card rounded="lg" link v-bind="attrs" v-on="on" style="height:100%;">
                        <v-card-text style="min-height:200px; width:100%;" class="d-flex flex-column justify-center align-center">
                            <v-btn :color="stat.global.color" fab depressed>
                                <v-icon color="white">${{ stat.global.icon }}</v-icon>
                            </v-btn>
                            <div class="value"><b>{{ stat.global.value }}</b></div>
                            <p class="textValue">{{$t(indexName)}}</p>
                        </v-card-text>
                    </v-card>
                </template>
                <v-card rounded="lg" class="no-shadow">
                    <v-card-text>
                        <template v-for="(substat, index) in stat">
                            <div v-if="index != 'global'" class="mb-2">
                                <div class="d-flex justify-space-between">
                                    <span class="substat">{{ (marketing && (indexName == 'opportunities' || indexName == 'leads')) ? (index) : ((indexName == 'fail' && !['unknown', 'passagenotcame', 'rdvnotcame'].includes(index)) ? index : $t(index)) }} ({{ substat }})</span>
                                    <div class="substatPercent">
                                        {{ getPercentage(substat, stat.global.value) }}%
                                    </div>
                                </div>
                                <v-progress-linear
                                color="info"
                                rounded
                                :value="getPercentage(substat, stat.global.value)"
                                ></v-progress-linear>
                            </div>
                        </template>
                    </v-card-text>
                </v-card>
            </v-menu>
            <v-card v-else rounded="lg" link style="height:100%;">
                <v-card-text style="min-height:200px; width:100%;" class="d-flex flex-column justify-center align-center">
                    <v-btn @click.stop="" :color="stat.global.color" fab depressed>
                        <v-icon color="white">${{ stat.global.icon }}</v-icon>
                    </v-btn>
                    <div class="value"><b>{{ stat.global.value }}</b></div>
                    <p class="textValue">{{stat.global.label ? $t(stat.global.label) : $t(indexName)}}</p>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="2">
            <v-card rounded="lg" @click="statDialog = true" style="height:100%;" class="bg-orange-reverse">
                <v-card-text style="min-height:200px; width:100%;" class="d-flex flex-column justify-center align-center">
                    <v-icon x-large class="testIcon" color="white">$circleplus_d</v-icon>
                    <p class="textStatValue white--text">{{$t('moreStats')}}</p>
                </v-card-text>
                <v-dialog fullscreen scrollable v-model="statDialog">
                    <v-card>
                        <v-toolbar tile flat dark  class="bg-gradient" height="64" style="height:80px;">
                            <div style="display:flex; width:100%; height:64px; align-items:center; justify-content:space-between;">
                                <v-btn x-large depressed  color="transparent" class="ml-2" dark @click.native="statDialog =false" ><v-icon small class="mr-2">$prev</v-icon> {{ $t("back") }}</v-btn>
                                <div class="d-flex align-center">
                                    <v-toolbar-title class="d-flex align-center">{{$t("moreStats")}}</v-toolbar-title>
                                </div>
                                <div>
                                    <v-btn outlined dark depressed @click.native="statDialog = false">{{  $t('close') }}</v-btn>
                                </div>
                            </div> 
                        </v-toolbar>
                       <v-card-text class="">
                            <v-tabs v-model="tabModel" hide-slider centered class="customTabs">
                                <div class="pa-3 rounded-lg d-flex">
                                    <v-tab class="px-0">
                                        <v-btn color="primary" depressed small :text="tabModel == 0 ? false : true">1 - {{ $t('contacts') }}</v-btn>
                                    </v-tab>
                                    <v-tab class="px-0">
                                        <v-btn color="primary" depressed small :text="tabModel == 1 ? false : true">2 - {{ $t('leadGeneration') }}</v-btn>
                                    </v-tab>
                                    <v-tab class="px-0">
                                        <v-btn color="primary" depressed small :text="tabModel == 2 ? false : true">3 - {{ $t('leadProcessing') }}</v-btn>
                                    </v-tab>
                                </div>

                                <v-tab-item eager>
                                    <statsContactsComponent v-show="tabModel == 0"></statsContactsComponent>
                                </v-tab-item>

                                <v-tab-item eager>
                                    <statsLeadGenerationComponent v-show="tabModel == 1" :operation="operation"></statsLeadGenerationComponent>
                                </v-tab-item>

                                <v-tab-item eager>
                                    <statsLeadProcessingComponent v-show="tabModel == 2" :operation="operation"></statsLeadProcessingComponent>
                                </v-tab-item>
                            </v-tabs>
                       </v-card-text>
                    </v-card>
                </v-dialog>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import GenericDataService from '@/services/GenericDataService';
import statsContactsComponent from '../operationsComponents/statsContactsComponent.vue';
import statsLeadGenerationComponent from '../operationsComponents/statsLeadGenerationComponent.vue';
import statsLeadProcessingComponent from '../operationsComponents/statsLeadProcessingComponent.vue';

export default {
    name: 'dashboardStatComponent',
    components: { statsContactsComponent, statsLeadGenerationComponent, statsLeadProcessingComponent },
    props: ["operationId"],
    data() {
        return{
            operation: {},
            tabModel: 0,
            stats: {},
            statDialog: false,
            marketing:false,
        }
    },
    mounted() {
       this.customRefresh() 
    },
    methods: {
        goTo(indexName){
            if(indexName == 'opportunities')
                this.$router.push({name: 'OperationsDetailOpportunity', params: {operation_id: this.operationId}})
            else if(indexName == 'leads')
                this.$router.push({name: 'OperationsDetailLead', params: {operation_id: this.operationId}})
            else if(indexName == 'rdvs')
                this.$router.push({name: 'OperationsDetailPlanning', params: {operation_id: this.operationId}})
            else
                this.$router.push({name: 'OperationsDetailLead', params: {operation_id: this.operationId}, query: {datatableFilters: '{\"realtime_status\":\"'+indexName.toUpperCase()+'\"}'}})
        },
        getPercentage(substat, global){
            if(global == 0)
                return 0;

            let percent = ((substat * 100)/global).toFixed(2);
            if(percent == 0)
                return 0;
            else return percent

        },
        customRefresh(){
            let url = ""
            if(this.$store.state.auth.currentUser.accountParameters.type && this.$store.state.auth.currentUser.accountParameters.type == 'marketing'){
                url = '/operation/getStatsMarketing?operation_id='+this.operationId;
                this.marketing = true
            } else {
                url = '/operation/getStatsV2?operation_id='+this.operationId;
                this.marketing = false
            }
            GenericDataService.getData(url).then((response) => {
                this.stats = response.data.data
            })
            GenericDataService.getData('/operation/' + this.operationId + '/get').then((response) => {
                this.operation = response.data.data;
            })
        }
    },
}
</script>
<style lang="scss">
.operationStat {
    .value {
        margin-top:20px;
        margin-bottom:5px;
        font-size:32px;
        font-weight:800;
    }
    
    .textValue {
        margin-bottom:0px;
    }
    .textStatValue {
        width:80%;
        text-align: center;
        line-height: 18px;
        font-weight:bold;
        font-size:18px;
        margin-top:20px;
        margin-bottom:0px;
    }
}
.testMenu {
    min-width:180px !important;
}
.substat {
    font-size:11px;
    font-weight:bold;
}
.substatPercent {
    font-size:11px;
    font-weight:bold;
}
.testIcon {
    font-size: 56px !important;
    height: 56px !important;
    width: 56px !important;
    svg {
        font-size: 56px !important;
        height: 56px !important;
        width: 56px !important;
    }
}
</style>